<template>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <img src="@/assets/img/zolly-hotline.svg" class="zippy-support" alt="" />
        <h2 class="mt-4">
            {{ $t('layout.not-found') }}
        </h2>
        <router-link :to="'/'"> Retourner à l'accueil</router-link>
    </div>
</template>

<script>
    export default {
        title: 'layout.not-found-title'
    }
</script>

<style>
    .zippy-support {
        width: 22%;
    }
</style>
